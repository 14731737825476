<template>
  <div>
    <breadcrumb name="Administration" title="Server" subtitle="Info" @onTitleClicked="$emit('cancel')" />
    <vx-card title="Server Information" class="mb-base">
      <vs-row vs-type="flex">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12">
          <vs-input label="Title" v-model="title" class="mt-5 w-full" name="title" />
          <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first("title") }}</span>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12">
          <vs-input label="Country Code" v-model="country_code" class="mt-5 w-full" name="country_code" />
          <span class="text-danger text-sm" v-show="errors.has('country_code')">{{ errors.first("country_code")
          }}</span>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12">
          <vs-input label="Manufacture" v-model="manufacture" class="mt-5 w-full" name="manufacture" />
          <span class="text-danger text-sm" v-show="errors.has('manufacture')">{{ errors.first("manufacture") }}</span>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12">
          <div class="con-select">
            <vs-select placeholder="Brands" multiple autocomplete class="selectExample" label="Brands"
              v-model="selectedBrands">
              <vs-select-item :key="index" :value="item.id" :text="item.name_en" v-for="(item, index) in brands" />
            </vs-select>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12">
         <div class="con-select">
           <vs-select
            placeholder="API's"
            autocomplete
            class="selectExample"
            label="API's"
            v-model="apis"
            >
            <vs-select-item :key="index" :value="item.value" :text="item.title" v-for="(item,index) in apisByBrand" />
          </vs-select>
         </div>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex">
        <vs-col vs-type="flex" vs-lg="3" vs-sm="12">
          <vs-checkbox v-model="is_default">Is default</vs-checkbox>
        </vs-col>
      </vs-row>
      <vs-divider></vs-divider>
      <vs-row vs-type="flex" vs-justify="center" vs-align="center" class="optionBtns">
        <vs-col vs-type="flex" vs-lg="3" vs-sm="12">
          <vs-button class="mr-5 save" icon-pack="feather" icon="icon-save" @click="confirmSaveData">Save
          </vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-lg="3" vs-sm="12">

          <vs-button class="ml-5 cancel" type="border" color="primary" @click="resetData">Cancel
          </vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>


<script>
import Breadcrumb from "../../../components/general/breadcrumb";

export default {
  name: "edit-server",
  components: { Breadcrumb },
  props: {
    item: {
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      title: "",
      country_code: "",
      manufacture: "",
      invalidTitle: false,
      invalidCountryCode: false,
      invalidManufacture: false,
      apis: '',
      apisByBrand: [
        {title: 'Peugeot', value: 'peugeot'},
        {title: 'MG', value: 'mg'},
        {title: 'Opel and Chevrolet', value: 'opel_chevrolet'}
      ],      is_default: false,
      brands: [],
      selectedBrands: []
    };
  },
  mounted() {
    const queryString = `per_page=100000000000000000000`;
    this.$httpCars
      .get(`/brands?${queryString}`)
      .then(res => {
        this.$vs.loading.close();
        this.brands = res.data.data.brands;
      })
      .catch(() => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Something Error",
          text: "Couldn't fetch data from the server",
          color: 'danger'
        });
      })
    this.title = this.item.title
    this.country_code = this.item.country_code
    this.manufacture = this.item.manufacture
    this.apis = this.item.apis
    this.selectedBrands = this.item.brands
    this.is_default = this.item.default
  },
  methods: {
    confirmSaveData() {
      this.$vs.dialog({
        title: "Confirm",
        text: "You are going to save these data!",
        color: "primary",
        accept: () => this.saveData(),
        type: "confirm",
      });
    },
    resetData() {
      this.title = '';
      this.country_code = '';
      this.manufacture = '';
      this.apis = {};
      this.$emit("cancel");
    },
    notifyUser(title, text, color) {
      this.$vs.notify({
        title: title,
        text: text,
        color: color
      });
    },
    saveData() {
      if (this.title.length === 0) {
        this.invalidTitle = true;
        this.notifyUser("Error", "Invalid inputs", "danger");
        return;
      } else {
        this.invalidTitle = false;
      }

      if (!this.country_code.length === 0) {
        this.invalidCountryCode = true;
        this.notifyUser("Error", "Invalid inputs", "danger");
        return;
      } else {
        this.invalidCountryCode = false;
      }

      if (this.manufacture.length === 0) {
        this.invalidManufacture = true;
        this.notifyUser("Error", "Invalid inputs", "danger");
        return;
      } else {
        this.invalidManufacture = false;
      }


      this.$vs.loading();
      this.$httpAdmin
        .post(`/servers/${this.item.id}`, {
          title: this.title,
          country_code: this.country_code,
          manufacture: this.manufacture,
          apis: this.apis,
          default: this.is_default,
          brands: this.selectedBrands
        })
        .then(res => {
          console.log(res);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Data is updated successfully",
            color: "success"
          });
          this.$emit("saved");
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Something Error",
            text: "Couldn't update data on the server",
            color: "danger"
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.switch {
  margin-bottom: 50px;
  float: right;
  padding-right: 45px;
  padding-left: 40px;
}

.clr {
  clear: both;
}

.product-img {
  height: 110px;
  width: 110px;
  object-fit: cover;
}

.optionBtns {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.hidden {
  visibility: hidden;
}

.bottom-right {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
</style>
